<template>
  <c-box
    w="100%"
    margin-bottom="0"
  >
    <c-flex
      w="100%"
      background-color="white"
      border-radius="12px"
      border="1px solid #f2f2f2"
      box-shadow="0px 5px 30px 0px #0000000D"
      px="25px"
      py="28px"
      align="center"
    >
      <c-box
        min-w="64px"
        w="64px"
        h="64px"
        margin-right="30px"
        overflow="hidden"
        border-radius="100%"
        background-color="lightGray.900"
      >
        <c-image
          :src="getPhotoUser(item.photoUrl)"
          :alt="item.firstName"
        />
      </c-box>
      <c-box flex-grow="1">
        <c-flex justify="space-between">
          <c-heading
            as="h3"
            font-size="18px"
            line-height="27px"
            font-weight="700"
            color="black.900"
            flex-grow="1"
          >
            {{ item.firstName }} {{ item.lastName }}
          </c-heading>
          <c-box
            as="span"
            :color="item.programs.status === 'active' ? 'primary.400' : 'red.900'"
            :border-color="
              item.programs.status === 'active' ? 'primary.400' : 'red.900'
            "
            border-width="1px"
            border-style="1px solid"
            border-radius="30px"
            px="14px"
            py="3px"
            font-size="12px"
            font-weight="500"
            display="inline-block"
            vertical-align="middle"
            margin-left="8px"
            align-self="flex-start"
            text-transform="capitalize"
          >
            {{ item.programs.status }}
          </c-box>
        </c-flex>
        <c-text
          v-if="item.programs"
          font-family="Roboto"
          font-size="14px"
          line-height="21px"
          color="primary.400"
          margin-bottom="8px"
        >
          {{ item.programs.name }} ({{ item.programs.programService }})
        </c-text>
      </c-box>
    </c-flex>
  </c-box>
</template>
<script>
import generalMixin from '@/utils/general-mixins'
export default {
  name: 'ManagementCardClient',  
  mixins: [generalMixin],
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>