var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-breadcrumb', {
    attrs: {
      "font-size": "14px",
      "line-height": "21px",
      "separator": "›",
      "d": ['none', 'block']
    }
  }, [_c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "color": "primary.400",
      "as": "router-link",
      "to": "/management/nutritionists"
    }
  }, [_vm._v(" Manajemen Ahli Gizi ")])], 1), _c('c-breadcrumb-item', [_c('c-breadcrumb-link', {
    attrs: {
      "is-current": ""
    }
  }, [_vm._v(" Detail Ahli Gizi ")])], 1)], 1), _c('c-divider', {
    attrs: {
      "my": "16px"
    }
  }), _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "mb": "30px"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px"
    }
  }, [_vm._v(" Detail Ahli Gizi ")]), _c('c-box', [_c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "red",
      "border-radius": "60px",
      "min-w": "114px",
      "h": "auto",
      "py": "13px",
      "px": "18px",
      "mr": "20px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px"
    },
    on: {
      "click": function click($event) {
        _vm.isOpen = true;
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-trash.svg'),
      "alt": "file program",
      "mr": "10px",
      "border-radius": "6px"
    }
  }), _vm._v(" Hapus ")], 1), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "60px",
      "h": "auto",
      "min-w": "114px",
      "py": "13px",
      "px": "18px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px",
      "as": "router-link",
      "to": {
        name: 'superadmin.nutritionists.edit',
        params: {
          id: _vm.id
        }
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-pencil.svg'),
      "alt": "file program",
      "mr": "10px",
      "border-radius": "6px"
    }
  }), _vm._v(" Edit ")], 1)], 1)], 1), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" Data Pribadi ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px"
    }
  }), _c('c-box', {
    attrs: {
      "mb": "43px"
    }
  }, [_c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nomor STR ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.str) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Email ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-weight": "500",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.email) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nama Depan ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.firstName) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Nama Belakang ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.lastName) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Gelar ")]), _vm._l(_vm.item.education, function (edu, index) {
    return _c('c-text', {
      key: 'gelar' + index,
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px"
      }
    }, [_vm._v(" " + _vm._s(edu.title) + " ")]);
  })], 2), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Foto ")]), _c('c-image', {
    attrs: {
      "src": _vm.getPhotoUser(_vm.item.photoUrl),
      "w": "320px",
      "h": "100%",
      "alt": "avatar",
      "border-radius": "6px",
      "object-fit": "cover"
    }
  })], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Pendidikan ")]), _vm._l(_vm.item.education, function (edu, index) {
    return _c('c-text', {
      key: 'edu' + index,
      attrs: {
        "color": "superDarkGray.900",
        "font-size": "18px",
        "line-height": "27px",
        "text-transform": "capitalize"
      }
    }, [_vm._v(" " + _vm._s(edu.level) + " " + _vm._s(edu.university === "0" ? edu.otherUniversity : _vm.getUniversityName(edu.university)) + " ")]);
  })], 2), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Spesialisasi / Keahlian ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.specialization) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Usia Klien ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.clientAgeHandled) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Bahasa ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.languages) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Quota ")]), _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.quota) + " ")])], 1), _c('c-form-control', {
    attrs: {
      "mb": "30px"
    }
  }, [_c('c-text', {
    attrs: {
      "mb": "2px",
      "color": "primary.400",
      "font-weight": "500",
      "font-size": "16px",
      "line-height": "24px"
    }
  }, [_vm._v(" Rating ")]), _vm.item.rating ? _c('c-text', {
    attrs: {
      "color": "superDarkGray.900",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_c('StarRating', {
    attrs: {
      "increment": 0.5,
      "read-only": true,
      "star-size": 26,
      "text-class": "hidden"
    },
    model: {
      value: _vm.item.rating,
      callback: function callback($$v) {
        _vm.$set(_vm.item, "rating", $$v);
      },
      expression: "item.rating"
    }
  })], 1) : _vm._e()], 1)], 1), _c('c-heading', {
    attrs: {
      "as": "h4",
      "font-family": "Montserrat",
      "font-weight": "bold",
      "font-size": "18px",
      "line-height": "27px"
    }
  }, [_vm._v(" Nama Klien ")]), _c('c-divider', {
    attrs: {
      "mt": "10px",
      "mb": "22px"
    }
  }), _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      "gap": "6"
    }
  }, _vm._l(_vm.clients, function (client) {
    return _c('CardClient', {
      key: client.id,
      attrs: {
        "item": client
      }
    });
  }), 1), _vm.clients.length == 0 ? _c('c-flex', {
    attrs: {
      "justify": "center",
      "align": "center",
      "p": "30px"
    }
  }, [_vm._v(" Tidak punya Klien ")]) : _vm._e(), _vm.clients.length > 0 ? _c('c-flex', {
    attrs: {
      "justify": "space-between",
      "py": "24px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "gray.900",
      "font-size": "14px",
      "line-height": "21px"
    }
  }, [_vm._v(" Show: ")]), _c('c-select', {
    attrs: {
      "min-w": "120px",
      "border-radius": "6px",
      "font-size": "14px",
      "ml": "13px",
      "size": "md"
    },
    on: {
      "change": _vm.onChangeRow
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  }, [_c('option', {
    attrs: {
      "value": "5"
    }
  }, [_vm._v(" 5 Rows ")]), _c('option', {
    attrs: {
      "value": "10"
    }
  }, [_vm._v(" 10 Rows ")]), _c('option', {
    attrs: {
      "value": "15"
    }
  }, [_vm._v(" 15 Rows ")]), _c('option', {
    attrs: {
      "value": "20"
    }
  }, [_vm._v(" 20 Rows ")])])], 1), _c('c-flex', {
    attrs: {
      "justify-content": "flex-end",
      "w": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "p": "0",
      "mr": "16px",
      "w": "32px",
      "h": "32px",
      "min-w": "32px",
      "variant": "outline",
      "border-radius": "30px",
      "background-color": "transparent",
      "is-disabled": _vm.currentPage === 1
    },
    on: {
      "click": _vm.prevPage
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-left",
      "size": "24px"
    }
  })], 1), _vm._l(_vm.pages, function (page_) {
    return _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            bg: 'rgba(0, 140, 129, 0.3)',
            color: 'primary.400'
          }
        },
        expression: "{\n            ':hover': {\n              bg: 'rgba(0, 140, 129, 0.3)',\n              color: 'primary.400',\n            },\n          }"
      }],
      key: page_,
      attrs: {
        "p": "0",
        "m": "0",
        "w": "32px",
        "h": "32px",
        "min-w": "32px",
        "mx": "4px",
        "border-radius": "30px",
        "background-color": "rgba(0, 140, 129, 0.3)",
        "color": "primary.400",
        "bg": page_ === _vm.currentPage ? ['rgba(0, 140, 129, 0.3)'] : ['transparent'],
        "as": "router-link",
        "to": {
          name: _vm.$route.name,
          query: Object.assign({}, _vm.$route.query, {
            page: page_
          })
        }
      }
    }, [_vm._v(" " + _vm._s(page_) + " ")]);
  }), _c('c-button', {
    attrs: {
      "p": "0",
      "ml": "16px",
      "w": "32px",
      "h": "32px",
      "min-w": "32px",
      "variant": "outline",
      "border-radius": "30px",
      "background-color": "transparent",
      "is-disabled": _vm.pages.length === _vm.page
    },
    on: {
      "click": _vm.nextPage
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-right",
      "size": "24px"
    }
  })], 1)], 2)], 1)], 1) : _vm._e(), _c('DeleteConfirm', {
    attrs: {
      "content": _vm.deletedText,
      "is-open": _vm.isOpen
    },
    on: {
      "on-delete": _vm.onDelete
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }