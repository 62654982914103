var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "margin-bottom": "0"
    }
  }, [_c('c-flex', {
    attrs: {
      "w": "100%",
      "background-color": "white",
      "border-radius": "12px",
      "border": "1px solid #f2f2f2",
      "box-shadow": "0px 5px 30px 0px #0000000D",
      "px": "25px",
      "py": "28px",
      "align": "center"
    }
  }, [_c('c-box', {
    attrs: {
      "min-w": "64px",
      "w": "64px",
      "h": "64px",
      "margin-right": "30px",
      "overflow": "hidden",
      "border-radius": "100%",
      "background-color": "lightGray.900"
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.getPhotoUser(_vm.item.photoUrl),
      "alt": _vm.item.firstName
    }
  })], 1), _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify": "space-between"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-size": "18px",
      "line-height": "27px",
      "font-weight": "700",
      "color": "black.900",
      "flex-grow": "1"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.firstName) + " " + _vm._s(_vm.item.lastName) + " ")]), _c('c-box', {
    attrs: {
      "as": "span",
      "color": _vm.item.programs.status === 'active' ? 'primary.400' : 'red.900',
      "border-color": _vm.item.programs.status === 'active' ? 'primary.400' : 'red.900',
      "border-width": "1px",
      "border-style": "1px solid",
      "border-radius": "30px",
      "px": "14px",
      "py": "3px",
      "font-size": "12px",
      "font-weight": "500",
      "display": "inline-block",
      "vertical-align": "middle",
      "margin-left": "8px",
      "align-self": "flex-start",
      "text-transform": "capitalize"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.programs.status) + " ")])], 1), _vm.item.programs ? _c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": "14px",
      "line-height": "21px",
      "color": "primary.400",
      "margin-bottom": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.item.programs.name) + " (" + _vm._s(_vm.item.programs.programService) + ") ")]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }