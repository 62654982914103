<template>
  <c-box flex-grow="1">
    <c-breadcrumb
      font-size="14px"
      line-height="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link
          color="primary.400"
          as="router-link"
          to="/management/nutritionists"
        >
          Manajemen Ahli Gizi
        </c-breadcrumb-link>
      </c-breadcrumb-item>
      <c-breadcrumb-item>
        <c-breadcrumb-link is-current>
          Detail Ahli Gizi
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />
    <c-flex
      justify="space-between"
      align="center"
      mb="30px"
    >
      <c-heading
        as="h3"
        font-family="Montserrat"
        font-weight="bold"
        font-size="24px"
        line-height="36px"
      >
        Detail Ahli Gizi
      </c-heading>
      <c-box>
        <c-button
          variant="solid"
          variant-color="red"
          border-radius="60px"
          min-w="114px"
          h="auto"
          py="13px"
          px="18px"
          mr="20px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          @click="isOpen = true"
        >
          <c-image
            :src="require('@/assets/icon-trash.svg')"
            alt="file program"
            mr="10px"
            border-radius="6px"
          />
          Hapus
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          border-radius="60px"
          h="auto"
          min-w="114px"
          py="13px"
          px="18px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          as="router-link"
          :to="{
            name: 'superadmin.nutritionists.edit',
            params: { id: id },
          }"
        >
          <c-image
            :src="require('@/assets/icon-pencil.svg')"
            alt="file program"
            mr="10px"
            border-radius="6px"
          />
          Edit
        </c-button>
      </c-box>
    </c-flex>

    <c-heading
      as="h4"
      font-family="Montserrat"
      font-weight="bold"
      font-size="18px"
      line-height="27px"
    >
      Data Pribadi
    </c-heading>
    <c-divider
      mt="10px"
      mb="22px"
    />

    <c-box mb="43px">
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Nomor STR
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ item.str }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Email
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-weight="500"
          font-size="18px"
          line-height="27px"
        >
          {{ item.email }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Nama Depan
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ item.firstName }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Nama Belakang
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ item.lastName }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Gelar
        </c-text>
        <c-text
          v-for="(edu, index) in item.education"
          :key="'gelar' + index"
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ edu.title }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Foto
        </c-text>
        <c-image
          :src="getPhotoUser(item.photoUrl)"
          w="320px"
          h="100%"
          alt="avatar"
          border-radius="6px"
          object-fit="cover"
        />
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Pendidikan
        </c-text>
        <c-text
          v-for="(edu, index) in item.education"
          :key="'edu' + index"
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
          text-transform="capitalize"
        >
          {{ edu.level }}
          {{
            edu.university === "0"
              ? edu.otherUniversity
              : getUniversityName(edu.university)
          }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Spesialisasi / Keahlian
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ item.specialization }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Usia Klien
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ item.clientAgeHandled }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Bahasa
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ item.languages }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Quota
        </c-text>
        <c-text
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          {{ item.quota }}
        </c-text>
      </c-form-control>
      <c-form-control mb="30px">
        <c-text
          mb="2px"
          color="primary.400"
          font-weight="500"
          font-size="16px"
          line-height="24px"
        >
          Rating
        </c-text>
        <c-text
          v-if="item.rating"
          color="superDarkGray.900"
          font-size="18px"
          line-height="27px"
        >
          <StarRating
            v-model="item.rating"
            :increment="0.5"
            :read-only="true"
            :star-size="26"
            text-class="hidden"
          />
        </c-text>
      </c-form-control>
    </c-box>

    <c-heading
      as="h4"
      font-family="Montserrat"
      font-weight="bold"
      font-size="18px"
      line-height="27px"
    >
      Nama Klien
    </c-heading>
    <c-divider
      mt="10px"
      mb="22px"
    />

    <c-grid
      w="100%"
      :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
      gap="6"
    >
      <CardClient
        v-for="client in clients"
        :key="client.id"
        :item="client"
      />
    </c-grid>
    <c-flex
      v-if="clients.length == 0"
      justify="center"
      align="center"
      p="30px"
    >
      Tidak punya Klien
    </c-flex>

    <c-flex
      v-if="clients.length > 0"
      justify="space-between"
      py="24px"
    >
      <c-flex align="center">
        <c-text
          color="gray.900"
          font-size="14px"
          line-height="21px"
        >
          Show:
        </c-text>
        <c-select
          v-model="perPage"
          min-w="120px"
          border-radius="6px"
          font-size="14px"
          ml="13px"
          size="md"
          @change="onChangeRow"
        >
          <option value="5">
            5 Rows
          </option>
          <option value="10">
            10 Rows
          </option>
          <option value="15">
            15 Rows
          </option>
          <option value="20">
            20 Rows
          </option>
        </c-select>
      </c-flex>

      <c-flex
        justify-content="flex-end"
        w="100%"
      >
        <c-flex
          justify-content="center"
          align="center"
        >
          <c-button
            p="0"
            mr="16px"
            w="32px"
            h="32px"
            min-w="32px"
            variant="outline"
            border-radius="30px"
            background-color="transparent"
            :is-disabled="currentPage === 1"
            @click="prevPage"
          >
            <c-icon
              name="chevron-left"
              size="24px"
            />
          </c-button>

          <c-button
            v-for="page_ in pages"
            :key="page_"
            v-chakra="{
              ':hover': {
                bg: 'rgba(0, 140, 129, 0.3)',
                color: 'primary.400',
              },
            }"
            p="0"
            m="0"
            w="32px"
            h="32px"
            min-w="32px"
            mx="4px"
            border-radius="30px"
            background-color="rgba(0, 140, 129, 0.3)"
            color="primary.400"
            :bg="
              page_ === currentPage
                ? ['rgba(0, 140, 129, 0.3)']
                : ['transparent']
            "
            as="router-link"
            :to="{ name: $route.name, query: { ...$route.query, page: page_ } }"
          >
            {{ page_ }}
          </c-button>

          <c-button
            p="0"
            ml="16px"
            w="32px"
            h="32px"
            min-w="32px"
            variant="outline"
            border-radius="30px"
            background-color="transparent"
            :is-disabled="pages.length === page"
            @click="nextPage"
          >
            <c-icon
              name="chevron-right"
              size="24px"
            />
          </c-button>
        </c-flex>
      </c-flex>
    </c-flex>

    <DeleteConfirm
      :content="deletedText"
      :is-open="isOpen"
      @on-delete="onDelete"
    />
  </c-box>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import CardClient from './card-client.vue'
import DeleteConfirm from './../delete-confirm.vue'
import StarRating from 'vue-star-rating'
import generalMixin from '@/utils/general-mixins'

export default {
  name: 'ManagementNutritionistsDetail',
  components: { CardClient, DeleteConfirm, StarRating },
  mixins: [generalMixin],
  data() {
    return {
      isOpen: false,
      page: 1,
      perPage: '10',
    }
  },
  computed: {
    ...mapGetters({
      item: 'suNutritionists/item',
      clients: 'suNutritionists/clientItems',
      meta: 'suNutritionists/meta',
      universities: 'general/universities',
    }),
    id() {
      return this.$route.params.id
    },
    currentPage() {
      return parseInt(this.$route.query.page ?? 1, 10)
    },
    pages() {
      let total = this.meta.total ?? 1
      let perPage = parseInt(this.perPage) ?? 10
      let length = Math.ceil(total / perPage)
      return Array.from({ length }, (_, idx) => idx + 1)
    },
    deletedText() {
      return `Anda yakin ingin hapus ahli gizi <br /> “${this.item.firstName} ${
        this.item.lastName ? ' ' + this.item.lastName : ''
      }”?`
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.page = route.query.page ? parseInt(route.query.page) : 1
        let params = new URLSearchParams()
        params.set('page', this.page)
        params.set('perpage', this.perPage)
        this.nutritionistsClients(this.id, params)
      },
    },
  },
  async mounted() {
    this.loadUniversities()
    this.detailNutritionists(this.id)
  },
  methods: {
    ...mapActions({
      detailNutritionists: 'suNutritionists/detailNutritionists',
      nutritionistsClients: 'suNutritionists/nutritionistsClients',
      deleteNutritionists: 'suNutritionists/deleteNutritionists',
      loadUniversities: 'general/loadUniversities',
    }),
    getUniversityName(id) {
      if (this.universities.length === 0) return ''
      const data = this.universities.find((dt) => dt.id.toString() === id)
      if (!_.isEmpty(data)) {
        return data.name
      }
      return ''
    },
    onDelete(state) {
      this.isOpen = state
      if (!this.isOpen) return
      this.deleteNutritionists(this.id)
        .then(() => {
          this.$toast({
            title: 'Success',
            description: 'Ahli Gizi has been deleted.',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'superadmin.nutritionists',
          })
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
    },
    onChangeRow() {
      this.page = 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      })
    },
    prevPage() {
      this.page -= 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      })
    },
    nextPage() {
      this.page += 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      })
    },
  },
}
</script>
